// REACT
import React from "react"
// MUI
import { Grid, Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { FeaturesList } from "../components/featuresList"
import { Link } from "../components/link"

// COMPONENT FUNCTION
const SunfishPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      sunfish: file(relativePath: { eq: "sunfish.jpg" }) {
        ...BsmFluidImage
      }
      laser: file(relativePath: { eq: "laser.png" }) {
        ...BsmFluidImage
      }
    }
  `)

  // FEATURES
  const sunfishFeatures = [
    "Fiberglass hull",
    "Fiberglass foils",
    "Aluminum spars",
    "Line kit",
    "Class legal sail Lightweight hull",
    "Kick-up rudder system",
    "Self-bailing cockpit",
    "Storage compartment in the cockpit",
  ]

  const laserFeatures = [
    "Ice Blue Fiberglass hull",
    "Aluminum spars",
    "Fiberglass foils ",
    "Harken Race upgrade Cunningham/Outhaul kit",
    "Harken Race upgrade Boom Vang / Kicker",
    "Gorilla tiller and extension",
    "Class legal Mark II sail",
    "60mm Harken ratchet block",
    "Padded toe strap",
    "Line kit",
  ]

  // COMPONENT
  return (
    <Layout title="Sunfish">
      <PageTitle title="SUNFISH AND LASER SAILBOATS" />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography paragraph>
            The Sunfish is a sailing icon. One of sailing’s best-known brands,
            it is unmatched in simplicity and performance making it popular for
            all ages and abilities. Designed as the ultimate beach craft, this
            maintenance free boat holds its resale value thanks to its robust
            construction, highlighted by hard chines and a flat underbody. The
            Sunfish can be stored almost anywhere and is a snap to car-top,
            making it a popular international class and easy to transport to
            your favorite beach for family fun.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ImageBlock>
            <ImageWithCaption
              fluid={data.sunfish.childImageSharp.fluid}
              caption="Sunfish sailboat drawing with colorful sail"
              displayCaption
              maxWidth={300}
            />
          </ImageBlock>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FeaturesList features={sunfishFeatures} title="Sunfish" />
        </Grid>

        <Grid item xs={12}>
          <Typography paragraph>
            The Laser is the world’s most popular adult racing class boat. True
            to box one-design standards, each Laser in the world is identical
            ensuring the best sailor on the water wins the race, not the boat.
            The Laser is a challenging boat that rewards athleticism, subtle
            steering and trimming techniques, as well as the tactical excellence
            of the sailor. The Laser is raced by young and old alike from the
            Club level all the way to the Olympics. The Laser comes standard as
            a race version.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FeaturesList features={laserFeatures} title="Laser" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ImageBlock>
            <ImageWithCaption
              fluid={data.laser.childImageSharp.fluid}
              caption="Laser sailboat drawing"
              displayCaption
              maxWidth={300}
            />
          </ImageBlock>
        </Grid>

        <Grid item xs={12}>
          <Typography paragraph>
            <Link to="/directions">Stop in</Link> and take a look at our
            available Sunfish and Lasers.
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default SunfishPage
